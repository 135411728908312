import PageHeader from "../pageHeader";

// styles
import "../../styles/pages/services/services.css";
import ImgLeftService from "../imgLeftService";
import ImgRightService from "../imgRightService";

const Services = () => {
  return (
    
    <section className="services-container">
      <PageHeader />
      <div className="container">
        <ImgLeftService
          img="/images/service-1.png"
          title={
            <div>
              AMBULATORY <br /> TRANSPORTATION
            </div>
          }
          paragraph=" Passengers are safely buckled into a spacious back seat where they can
          enjoy a one-on-one conversation with one of our caring transportation
          specialists, listen to their favorite radio station or a quiet
          peaceful ride."
        />
        <ImgRightService
          img="/images/service-2.png"
          title={
            <div>
              WHEELCHAIR <br /> TRANSPORTATION
            </div>
          }
          paragraph="Each wheelchair van is equipped with a ramp in the back. Passengers are wheeled in and locked into place for a secure and safe ride to their location."
        />
        <ImgLeftService
          img="/images/service-3.png"
          title={
            <div>
              BARIATRIC <br /> TRANSPORTATION
            </div>
          }
          paragraph="Passengers will be transported based on their specific needs,  whether it is ambulatory, wheelchair, or stretcher."
        />
        <ImgRightService
          img="/images/service-4.png"
          title={
            <div>
              STRETCHER <br /> TRANSPORTATION
            </div>
          }
          paragraph="Our stretcher transportation services are available to pick up patients in OKC, Edmond, Del City, MWC, and Bethany. However, we can transport to anywhere in Oklahoma."
        />
      </div>
    </section>
  );
};

export default Services;

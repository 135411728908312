import { useLocation } from "react-router-dom";

const PageHeader = () => {
  const location = useLocation();
  const path = location.pathname.substring(1);

  return (
    <div className="page-header">

      {path === "services" ? (
        <h3>Our Services</h3>
      ) : path === "contact" ? (
        <h3>Contact Us</h3>
      ) : path === "about-us" ? (
        <h3>About Us</h3>
      ) : path === "faqs" ? (
        <div>
          <h4>FAQ</h4>
          <p>
            Answers to some commonly <br /> asked questions
          </p>
        </div>
      ) : path === "careers" ? (
        <h3>Careers</h3>
      ) : path === "facilityServices" ? (
        <div>
          <h3>Facility Services</h3>
          <p className="facility-services-desc">
            We work with multiple facilities serving some or all their
            non-emergency medical transportation needs.
          </p>
        </div>
      ) : (
        " "
      )}
    </div>
  );
};

export default PageHeader;

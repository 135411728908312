import React from "react";
import PatternVertical from "./PatternVertical";

const ImgRightService = (props) => {
  return (
    <div className="row">
      <div className="text-content">
        <div className="row">
          <PatternVertical style="title-pattern" />
          <h1>{props.title}</h1>
        </div>
        <p>{props.paragraph}</p>
      </div>
      <div className="img-container">
        <img src={props.img} alt="Car" />
      </div>
    </div>
  );
};

export default ImgRightService;

export const openPositions = [
    {
        id: 1,
        title: 'Intake Coordinator - Hospice/Home Health',
        description: 'The Psychiatry Mid-Level Provider will be responsible for providing comprehensive psychiatric care to a diverse patient population, including evaluation, diagnosis, treatment, and follow-up. The Psychiatry Mid-Level Provider will work collaboratively with a multidisciplinary team to deliver high-quality mental health services in a clinic setting.',
        position_time: 'Full time'
    },

    {
        id: 2,
        title: 'Intake Coordinator - Hospice/Home Health',
        description: 'The Psychiatry Mid-Level Provider will be responsible for providing comprehensive psychiatric care to a diverse patient population, including evaluation, diagnosis, treatment, and follow-up. The Psychiatry Mid-Level Provider will work collaboratively with a multidisciplinary team to deliver high-quality mental health services in a clinic setting.',
        position_time: 'Full time'
    },

    {
        id: 3,
        title: 'Intake Coordinator - Hospice/Home Health',
        description: 'The Psychiatry Mid-Level Provider will be responsible for providing comprehensive psychiatric care to a diverse patient population, including evaluation, diagnosis, treatment, and follow-up. The Psychiatry Mid-Level Provider will work collaboratively with a multidisciplinary team to deliver high-quality mental health services in a clinic setting.',
        position_time: 'Full time'
    },

    {
        id: 4,
        title: 'Intake Coordinator - Hospice/Home Health',
        description: 'The Psychiatry Mid-Level Provider will be responsible for providing comprehensive psychiatric care to a diverse patient population, including evaluation, diagnosis, treatment, and follow-up. The Psychiatry Mid-Level Provider will work collaboratively with a multidisciplinary team to deliver high-quality mental health services in a clinic setting.',
        position_time: 'Full time'
    },
]

export const testimonialss = [
    {
        id: 1,
        name: 'Larry and Carol E.',
        description: '“We want to thank you so much for making our 32nd anniversary special. We were able to relax and enjoy our outing. You made that possible. It’s nice to know there are still kind people in the world. Thank you so much.”'
    },

    {
        id: 2,
        name: 'Larry and Carol E.',
        description: '“We want to thank you so much for making our 32nd anniversary special. We were able to relax and enjoy our outing. You made that possible. It’s nice to know there are still kind people in the world. Thank you so much.”'
    },

    {
        id: 3,
        name: 'Larry and Carol E.',
        description: '“We want to thank you so much for making our 32nd anniversary special. We were able to relax and enjoy our outing. You made that possible. It’s nice to know there are still kind people in the world. Thank you so much.”'
    },

    {
        id: 4,
        name: 'Larry and Carol E.',
        description: '“We want to thank you so much for making our 32nd anniversary special. We were able to relax and enjoy our outing. You made that possible. It’s nice to know there are still kind people in the world. Thank you so much.”'
    }
]
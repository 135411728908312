import { useState } from "react";
import { IoMailOutline, IoPhonePortraitOutline } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import '../../styles/pages/contact/contact.css';
import PrimaryButton from "../primaryButton";
import PatternVertical from "../PatternVertical";
import axios from "axios";
import toast from "react-hot-toast";
import ContactForm from "../contactForm";

const Contact = () => {
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [message, setMessage] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const handleContact = async () => {
    setIsLoading(true)
    const emailData = {
      "hospital_name": "Cohesive Medyride",
      "recipient_email": "mediryde@chmcok.com",
      "full_name": name,
      "email_address": email,
      "message": message,
      "subject": "subject"
    }

    try {
      const res = await axios.post("http://127.0.0.1:8000/api/contact/", emailData)
      console.log(res.data)
      if (res.status === 200) {
        toast.success('Message sent successfully')
        setName('')
        setEmail('')
        setMessage('')
        setIsLoading(false)
      }
      setIsLoading(false)
    } catch (error) {
      if (error.response.data.error) {
        toast.error(error.response.data.error)
      } else {
        toast.error("Error sending message")
      }
      setIsLoading(false)
    }
  }
  return (
    <section className='contact-us'>
      <div className="contact-header">
        <h3>Contact Us</h3>
      </div>
      <div className="container">
        <div className="row">
          <div className="col col-1">
            <div className="title-contents">
              <PatternVertical style={`vertical-pattern`} />
              <div className="title">
                <h1>Get in Touch:</h1>
                <p className="subtitle">“We're Here to Help”</p>
              </div>
            </div>
            <p className="desc">If you have any questions, want to know if our services are available in your area, or want a price quote, please fill out the form  or reach out to us via email or phone. We'll be happy to answer all your questions.</p>
            <div className="mail-link">
              <div className="icon">
                <IoMailOutline />
              </div>
              <div className="mail">
                <p>mediryde@chmcok.com</p>
              </div>
            </div>
            <div className="mail-link">
              <div className="icon">
                <IoPhonePortraitOutline />
              </div>
              <div className="mail">
                <p> (405) 395-4474</p>
              </div>
            </div>
          </div>
          <div className="col col-2">
            <h1 className="form-title">Send us  a message</h1>
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact

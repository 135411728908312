import PageHeader from "../pageHeader"
import PatternVertical from "../PatternVertical"
// icons
import { IoLocationOutline, IoPhonePortraitOutline } from "react-icons/io5";

// styles
import '../../styles/pages/about/about.css';

const About = () => {
  return (
    <section className='about-us'>
      <PageHeader />
      <div className="container">
        <div className="row row-1">
          <div className="col col-1">
            <div className='img-1'>
              <img src="/images/wheel-transport-2.svg" alt="" />
            </div>
            <div className='img-2'>
              <img src="/images/wheel-transport.svg" alt="" />
            </div>
            <div className="col-pattern">
              <PatternVertical style={`pattern`} />
            </div>
          </div>
          <div className="col">
            <div className="title">
              <PatternVertical style={`title-pattern`} />
              <h1>OUR APPROACH</h1>
            </div>
            <p>Our team of transportation specialists are passionate about the part we get to play in keeping our community healthy and  we are dedicated to ensuring each passenger is happy and safe throughout their trip. We  provide transportation for:</p>
            <div className="services-container">
              <div className="services-contents">
                <PatternVertical style={`pattern`} />
                <div className="service">
                  <p>Appointments</p>
                  <p>Dialysis Treatments</p>
                  <p>Cancer Treatments</p>
                  <p>Hospital Releases</p>
                </div>
              </div>
              <div className="services-contents">
                <PatternVertical style={`pattern`} />
                <div className="service">
                  <p>Emergency Room Visits</p>
                  <p>Special Occasions</p>
                  <p>Pharmacy Pickups</p>
                  <p>Personal Engagement</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-2">
          <div className="col">
            <h1>SERVICE AREA</h1>
            <div className="title">
              <PatternVertical style={`title-pattern`} />
              <p>We serve <br />all of Oklahoma!</p>
            </div>
            <div className="title">
              <div className="icon">
                <IoLocationOutline />
              </div>
              <h3>Office Location</h3>
            </div>
            <p className="location">4100 Perimeter Center Dr. Ste. 140 <br />Oklahoma City, OK 73112</p>
            <div className="title">
              <div className="icon">
                <IoPhonePortraitOutline />
              </div>
              <h3>Phone number</h3>
            </div>
            <p className="phone-number">(405) 395-4474</p>
          </div>
          <div className="col col-img">
            <img src="/images/map.svg" alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default About

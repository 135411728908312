import '../styles/serviceslist.css'
import { useGlobalContext } from './context/Context'

const ServicesList = () => {
    const { closeServices } = useGlobalContext()

    return (
        <div className='list-services'>
            <div className='list-service'>
                <div className="service" >
                    <a onClick={closeServices} href="/services">Individual Services</a>
                </div>
                <div className="service">
                    <a onClick={closeServices} href="/facilityServices">Facility Services</a>
                </div>
            </div>
        </div>
    )
}

export default ServicesList

import PageHeader from "../pageHeader";
import { LuMinusCircle } from "react-icons/lu";
import { LuPlusCircle } from "react-icons/lu";
import "../../styles/pages/faq/faq.css";
import Contact from "./Contact";
import { useState } from "react";

const Faq = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const onAccordionClick = (index) => {
    setActiveAccordion(index === activeAccordion ? null : index);
  };

  // const toggleAccordion = () => {
  //   setAccordion(!accordion);
  // };
  const items = [
    {
      title: "Can I call MediRyde for transportation to non-medical destinations?",
      content: "Our primary function is to provide transportation to those who may not have other options as a way to keep up with scheduled medical appointments. However, we do take passengers to non-medical destinations.",
    },
    {
      title: "Do you supply oxygen to passengers?",
      content: "We do have oxygen available for patients for a small fee. If you need oxygen, please notify us when you schedule your appointment. We will have it set up for you on pickup.",
    },
    {
      title: "Are your drivers licensed? ",
      content: "Yes. Each driver is licensed and PASS and defensive driving certified. Some of our drivers are licensed EMTs who are BLS certified. They are also background checked and drug screened.",
    },
    {
      title: "How much does transport cost?",
      content: "The cost of transport is based on many factors, including destination, whether it is one-way or round trip, and what type of transportation is needed.",
    },
  ];
  return (
    <section className="faqs">
      <PageHeader />
      <div className="container">
        <div className="accordion">
          <div className="main-accordion">
            <ul>
              {items.map((item, index) => {
                const mainIndex = index + 1;

                return (
                  <div key={index}>
                    <li
                      onClick={() => onAccordionClick(index)}
                      className={
                        activeAccordion === index
                          ? "accordion-active main-accordion"
                          : "main-accordion"
                      }
                    >
                      <h1>{item.title}</h1>

                      {activeAccordion === index ? (
                        <LuMinusCircle
                          className="minus-icon"
                          onClick={() => onAccordionClick(index)}
                        />
                      ) : (
                        <LuPlusCircle
                          className="plus-icon"
                          onClick={() => onAccordionClick(index)}
                        />
                      )}
                    </li>
                    {activeAccordion === index && (
                      <div className="accordion-content accordion-content-mobile">
                        {activeAccordion !== null && (
                          <p>{items[activeAccordion].content}</p>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </ul>
            <div className="accordion-content accordion-content-desktop">
              {activeAccordion !== null && (
                <p>{items[activeAccordion].content}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </section>
  );
};

export default Faq;

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/footer";
import TopNav from "./components/topNav";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Faq from "./components/pages/Faq";
import Services from "./components/pages/Services";
import FacilityService from "./components/pages/FacilityService";
import Careers from "./components/pages/Careers";
import Testimonials from "./components/pages/Testimonials";
import { Toaster } from "react-hot-toast";
function App() {
  return (
    <>
      <Router>
        <TopNav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/faqs" element={<Faq />} />
          <Route path="/services" element={<Services />} />
          <Route path="/facilityServices" element={<FacilityService />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/testimonials" element={<Testimonials />} />
        </Routes>
        <Footer />
      </Router>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
    </>

  );
}

export default App;

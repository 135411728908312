import React from 'react'

function PrimaryButton({title, style}) {
    return (
      <>
      <button type='submit' className={style}>
        {title}
      </button>
      </>
    )
}

export default PrimaryButton

import React from 'react'

const PatternVertical = ({style}) => {
  return (
    <div className={style}>
      
    </div>
  )
}

export default PatternVertical

import React from "react";
import PageHeader from "../pageHeader";
import ImgRightService from "../imgRightService";
import ImgLeftService from "../imgLeftService";
import "../../styles/pages/services/services.css";
import Contact from "./Contact";
import PatternVertical from "../PatternVertical";

const FacilityService = () => {
  return (
    <section className="facility-service services-container">
      <PageHeader />

      <div className="container">
        <div className="row">
          <div className="img-container">
            <img src="/images/facility-service-1.png" alt="Car" />
          </div>
          <div className="text-content">
            <div className="row">
              <PatternVertical style="title-pattern" />
              <h1>SERVICES OFFERED</h1>
            </div>
            <p>
              Whether your patient is ambulatory, wheelchair-bound, bariatric,
              or in needing a stretcher, we can transport them. We offer the
              following services:
            </p>
            <div className="row">
              <div className="row-1">
                <PatternVertical style="title-pattern" />
                <div className="col">
                  <p>Hospital Appointments</p>
                  <p>Clinic Appointments</p>
                </div>
              </div>
              {/* <div className="row-2">
                <PatternVertical style="title-pattern" />
                <p>From facility to home</p>
              </div> */}
              <div className="row-1">
                <PatternVertical style="title-pattern" />
                <div className="col">
                  <p>Local Facilities</p>
                  <p>To and From Work and Home</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ImgRightService
          img="/images/facility-service-2.png"
          title={<div>DAYTIME SERVICES</div>}
          paragraph="If your facility requires assistance getting patients back and forth from medical appointments, we're happy to help, whether  on a case-by-case basis or a contract for all your patients."
        />
        <ImgLeftService
          img="/images/facility-service-3.png"
          title={<div>AFTER HOUR SERVICES</div>}
          paragraph="If your facility has transport but your patients need transportation services after hours, we can provide on-call services for all non-emergency patients at a fraction of the cost of an ambulance."
        />
      </div>
      <Contact />
    </section>
  );
};

export default FacilityService;

import React, { useState } from 'react'
import toast from 'react-hot-toast'
import axios from 'axios'
const ContactForm = () => {
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [message, setMessage] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const handleContact = async () => {
        setIsLoading(true)
        const emailData = {
            "hospital_name": "Cohesive Medyride",
            "recipient_email": "mediryde@chmcok.com",
            "full_name": name,
            "email_address": email,
            "message": message,
            "subject": "subject"
        }

        try {
            const res = await axios.post("https://emails-dp0z.onrender.com/api/contact/", emailData)
            console.log(res.data)
            if (res.status === 200) {
                toast.success('Message sent successfully')
                setName('')
                setEmail('')
                setMessage('')
                setIsLoading(false)
            }
            setIsLoading(false)
        } catch (error) {
            if (error.response.data.error) {
                toast.error(error.response.data.error)
            } else {
                toast.error("Error sending message")
            }
            setIsLoading(false)
        }
    }
    return (
        <form>
            <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Name" />
            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Your email" />
            <textarea value={message} onChange={(e) => setMessage(e.target.value)} cols="30" rows="10" placeholder="Message"></textarea>
            <button onClick={handleContact} type="button" className="button primary-button">
                {isLoading ? 'Sending...' : 'Send message'}
            </button>
        </form>
    )
}

export default ContactForm

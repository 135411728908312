import React, { useState, useContext } from "react";

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
    const [isServicesOpen, setIsServicesOpen] = useState(false)

    const openServices = () => {
        console.log('clicked')
        setIsServicesOpen(true)
    }

    const closeServices = () => {
        setIsServicesOpen(false)
    }

    return (
        <AppContext.Provider value={{
            isServicesOpen,
            setIsServicesOpen,
            openServices,
            closeServices
        }}>
            {children}
        </AppContext.Provider>
    )
}

export const useGlobalContext = () => {
    return useContext(AppContext)
}

export { AppContext, AppProvider }
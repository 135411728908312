import { useState } from 'react';
// styles
import '../../styles/pages/careers/careers.css';

// components
import PageHeader from "../pageHeader"
import PatternVertical from "../PatternVertical"
import { openPositions } from "../../constants"
// icons
import { MdArrowOutward } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";

const Careers = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false)

    const openPopup = () => {
        setIsPopupOpen(true)
    }

    const closePopup = () => {
        setIsPopupOpen(false)
    }


    return (
        <>
            {isPopupOpen && (
                <div className="popup">
                    <div className="popup-contents">
                        <div className="title">
                            <h3>Position Summary</h3>
                            <button onClick={closePopup}>
                                <IoIosCloseCircleOutline />
                            </button>
                        </div>
                        <div className="position-details">
                            <h1>Psychiatry Mid-Level Provider - APRN</h1>
                            <p>POSITION SUMMARY :The Psychiatry Mid-Level Provider will be responsible for providing comprehensive psychiatric care to a diverse patient population, including evaluation, diagnosis, treatment, and follow-up. The Psychiatry Mid-Level Provider will work collaboratively with a multidisciplinary team to deliver high-quality mental health services in a clinic setting.</p>
                            <p>POSITION SUMMARY EXPANDED:Under the Oklahoma Medical Marijuana and Patient Protection Act, (“The Unity Bill”) this position is considered a “Safety-sensitive position. “Safety-sensitive” is defined to include “any job that includes tasks or duties that could affect the safety and health of the employee performing the task or others.” Employees working in “safety-sensitive” roles are subject to this exception and subject to disciplinary action in the event of a positive test for marijuana or its metabolites.</p>
                            <div className="link">
                                <a href="" className='apply-link'>
                                    <span>Apply Now</span> <MdArrowOutward />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <section className="careers">
                <PageHeader />
                <div className="container">
                    <div className="title">
                        <PatternVertical style={`title-pattern`} />
                        <h1>OUR OPEN POSITIONS</h1>
                    </div>
                    {openPositions.length > 0 ? (
                        <div className="open-positions-container">
                            {openPositions.map((position) => (
                                <div className="open-positions">
                                    <div className="position" key={position.id}>
                                        <div className="position-title">
                                            <h1>{position.title}</h1>
                                            <p>{position.position_time}</p>
                                        </div>
                                        <p>{position.description.slice(0, 180)}...</p>
                                        <button className='details-link' onClick={openPopup}>
                                            <span>More details</span> <MdArrowOutward />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : 'No open positions'}
                </div>
            </section>
        </>
    )
}

export default Careers
